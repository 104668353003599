import React, { useEffect } from "react";
import { ChevronRightIcon, StarIcon } from "@heroicons/react/20/solid";
import ZapierForm from "react-zapier-form";
import TextTransition, { presets } from "react-text-transition";
import { classNames } from "./shared";
import MainLogos from "./MainLogos";

import { useMediaQuery } from "react-responsive";

import Div100vh from "react-div-100vh";

const TEXTS = [
  "Artists",
  "Podcast creators",
  "Songwriters",
  "Publishers",
  "Musicians",
  "Managers",
  "Producers",
  "Labels",
  "Music.",
];

const MainHero = ({ scrolled }) => {
  const [index, setIndex] = React.useState(0);
  const [play, setPlay] = React.useState(false);

  React.useEffect(() => {
    for (let i = 0; i < TEXTS.length; i++) {
      setTimeout(() => setIndex(i), 400 * i);
    }

    setTimeout(() => setPlay(true), 400 * (TEXTS.length - 1) + 100);

    // return () => clearTimeout(intervalId);
  }, []);

  function supportsHEVCAlpha() {
    const navigator = window.navigator;
    const ua = navigator.userAgent.toLowerCase();
    const hasMediaCapabilities = !!(
      navigator.mediaCapabilities && navigator.mediaCapabilities.decodingInfo
    );
    const isSafari =
      ua.indexOf("safari") != -1 &&
      !(ua.indexOf("chrome") != -1) &&
      ua.indexOf("version/") != -1;
    return isSafari && hasMediaCapabilities;
  }

  const isMobile = useMediaQuery({ query: `(max-width: 767px)` });

  useEffect(() => {
    if (play) {
      var vid = document.getElementById("video");

      vid.play();
    }
  }, [play]);

  return (
    <div
      className={classNames(
        isMobile && "mobile-background",
        " lg:main-background pb-2 flex-col min-h-[calc(100vh-4rem)] flex justify-center "
      )}
    >
      <div className=" pt-8 sm:pt-12 lg:pt-0  lg:my-auto">
        <div className="mx-auto  max-w-md px-4 sm:max-w-3xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-24 lg:px-8">
          <div>
            <div>
              <div className="mt-6 lg:mt-0 sm:max-w-xl">
                <div
                  className={classNames(
                    "text-5xl font-bold tracking-tight   leading-[1.15] text-gray-800 sm:text-5xl lg:text-[4.2rem]"
                  )}
                >
                  <div className="z-10  ">Project management for</div>
                  <div className="z-0">
                    <TextTransition
                      className={classNames(
                        index === TEXTS.length - 1
                          ? "text-green-500"
                          : "text-gray-500",
                        " bg-white w-fit transition-all px-4 -mx-4   lowercase z-0  "
                      )}
                      style={{
                        zIndex: 0,
                      }}
                      springConfig={presets.wobbly}
                    >
                      {TEXTS[index]}
                    </TextTransition>
                  </div>
                </div>
              </div>
              <div
                className={classNames(
                  play ? "" : "",
                  " md:mt-24 hidden lg:block md:max-w-[28rem] transition-all duration-1000 mt-16  lg:mr-12 rounded-lg"
                )}
              >
                <p className=" text-lg text-gray-700">
                  Trackflow is currently in closed beta, please fill in your
                  email if you'd like to be involved.
                </p>
                <ZapierForm action="https://hooks.zapier.com/hooks/catch/12946224/bnhou31/">
                  {({ error, loading, success }) => {
                    return (
                      <div className="flex mt-6">
                        {!success && !loading && (
                          <>
                            <div className="min-w-0 flex-1">
                              <label htmlFor="hero-email" className="sr-only">
                                Email address
                              </label>
                              <input
                                id="hero-email"
                                type="email"
                                name="email"
                                className="block w-full border rounded-md  border-gray-300 px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm  ring-offset-0 focus:border-green-500 focus:ring-green-500"
                                placeholder="Enter your email"
                              />
                            </div>
                            <div className="mt-4 sm:mt-0 sm:ml-3">
                              <button
                                type="submit"
                                className="block w-full rounded-md border border-transparent bg-gray-200 px-5 py-3 text-base font-medium text-gray-800  hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:px-6"
                              >
                                Sign up
                              </button>
                            </div>
                          </>
                        )}
                        <div className="text-lg  font-medium">
                          {loading && <div>Loading...</div>}
                          {error && (
                            <div>
                              Something went wrong. Please try again later.
                            </div>
                          )}
                          {success && <div>Thank you! We'll be in touch.</div>}
                        </div>
                      </div>
                    );
                  }}
                </ZapierForm>
              </div>
              {/* <div className="mt-6">
                <div className="inline-flex items-center divide-x divide-gray-300">
                  <div className="flex flex-shrink-0 pr-5">
                    <StarIcon
                      className="h-5 w-5 text-yellow-400"
                      aria-hidden="true"
                    />
                    <StarIcon
                      className="h-5 w-5 text-yellow-400"
                      aria-hidden="true"
                    />
                    <StarIcon
                      className="h-5 w-5 text-yellow-400"
                      aria-hidden="true"
                    />
                    <StarIcon
                      className="h-5 w-5 text-yellow-400"
                      aria-hidden="true"
                    />
                    <StarIcon
                      className="h-5 w-5 text-yellow-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="min-w-0 flex-1 py-1 pl-5 text-sm text-gray-500 sm:py-3">
                    <span className="font-medium text-gray-900">
                      Rated 5 stars
                    </span>{" "}
                    by over{" "}
                    <span className="font-medium text-green-600">
                      100 beta users
                    </span>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
          <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <div className="block">
              <div
                // style={{
                //   left: 100 - (index / TEXTS.length) * 90 + "%",

                // }}
                className={classNames(
                  // play ? "left-1/4 " : "left-full ",
                  "left-1/4",
                  "absolute hidden lg:block duration-500 right-0 transition-all -z-10 -top-[30vh] rounded-full aspect-square lg:bg-green-500 bg-gray-100  lg:right-0 lg:h-[140vh]"
                )}
              />
            </div>
            <div className="relative  pl-4 mx-auto sm:max-w-3xl pb-32 lg:pb-12 sm:px-0 lg:h-full flex items-center lg:max-w-none lg:pl-12">
              {/* {(play || isMobile) && ( */}
              <div className="video">
                <video
                  id="video"
                  src={
                    supportsHEVCAlpha() || isMobile
                      ? "./video.mov"
                      : "./video.webm"
                  }
                  autoPlay
                  loop
                  muted
                  playsInline
                />
              </div>
              {/* )} */}
            </div>
          </div>
        </div>
        <div
          className={classNames(
            scrolled ? "opacity-100 bg-white " : " opacity-100",
            "fixed bottom-0  z-50 w-full lg:hidden transition-all   rounded-t-lg"
          )}
        >
          <ZapierForm action="https://hooks.zapier.com/hooks/catch/12946224/bnhou31/">
            {({ error, loading, success }) => {
              return (
                <div className="flex  flex-row m-6 mb-6">
                  {!success && !loading && (
                    <>
                      <div className="min-w-0 flex-1">
                        <label htmlFor="hero-email" className="sr-only">
                          Email address
                        </label>
                        <input
                          id="hero-email"
                          type="email"
                          name="email"
                          className="block w-full text-lg border rounded-md font-medium border-gray-300 px-5 py-3  text-gray-900 placeholder-gray-500 shadow-sm  ring-offset-0 focus:border-green-500 focus:ring-green-500"
                          placeholder="Enter your email"
                        />
                      </div>
                      <div className="ml-3 ">
                        <button
                          type="submit"
                          className={classNames(
                            scrolled
                              ? "bg-green-500 text-white hover:bg-green-600"
                              : " bg-gray-200 text-gray-800  hover:bg-gray-300 ",
                            "block w-full text-lg rounded-md border border-transparent  px-5 py-3  font-medium focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:px-6"
                          )}
                        >
                          Sign up
                        </button>
                      </div>
                    </>
                  )}
                  <div
                    className={classNames(
                      scrolled ? "text-green-600" : "text-white",
                      "text-lg    font-medium"
                    )}
                  >
                    {loading && <div>Loading...</div>}
                    {error && (
                      <div>Something went wrong. Please try again later.</div>
                    )}
                    {success && <div>Thank you! We'll be in touch.</div>}
                  </div>
                </div>
              );
            }}
          </ZapierForm>
        </div>
      </div>
      {/* <MainLogos /> */}
    </div>
  );
};

export default MainHero;
