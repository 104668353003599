import NavBar from "./components/NavBar";

import { useEffect, useState } from "react";

import Lottie from "react-lottie-player";

import circularLinesAnimation from "./animations/donut-pie-chart.json";
import MainHero from "./components/MainHero";
import MainFeatures from "./components/MainFeatures";
import KnowMore from "./components/KnowMore";
import MainLogos from "./components/MainLogos";
import LearnMoreArrow from "./components/LearnMoreArrow";
import Footer from "./components/Footer";
import ShortFeatures from "./components/ShortFeatures";
import Feature1 from "./components/Feature1";
import Feature2 from "./components/Feature2";
import CompanyType from "./components/CompanyType";
import FooterBeta from "./components/FooterBeta";

export default function App() {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.onscroll = function () {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
  }, []);
  return (
    <div className="">
      <NavBar scrolled={scrolled} />

      <MainHero scrolled={scrolled} />
      {/* <CompanyType /> */}
      {/* <Feature1 />
      <ShortFeatures />
      <Feature2 />*/}
      <FooterBeta />
    </div>
  );
}
