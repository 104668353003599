import {
  PencilIcon,
  AdjustmentsVerticalIcon,
  StarIcon,
  MicrophoneIcon,
  RocketLaunchIcon,
  ArrowPathIcon,
  BeakerIcon,
  BellIcon,
  BoltIcon,
  BookOpenIcon,
  ChartBarIcon,
  CameraIcon,
  ChartPieIcon,
  CalendarDaysIcon,
  ChatBubbleLeftRightIcon,
  ChatBubbleLeftIcon,
  CloudArrowDownIcon,
  CloudArrowUpIcon,
  CurrencyEuroIcon,
  CurrencyPoundIcon,
  CurrencyDollarIcon,
  FingerPrintIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  HeartIcon,
  LockClosedIcon,
  LockOpenIcon,
  PhoneIcon,
  TvIcon,
  XMarkIcon,
  AcademicCapIcon,
  ArrowRightCircleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/solid";

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const getStatusIcon = (iconName) => {
  switch (iconName) {
    case "PencilIcon":
      return <PencilIcon />;
    case "MicrophoneIcon":
      return <MicrophoneIcon />;
    case "AdjustmentsVerticalIcon":
      return <AdjustmentsVerticalIcon />;
    case "StarIcon":
      return <StarIcon />;
    case "AcademicCapIcon":
      return <AcademicCapIcon />;
    case "ArrowRightCircleIcon":
      return <ArrowRightCircleIcon />;
    case "RocketLaunchIcon":
      return <RocketLaunchIcon />;
    case "ArrowPathIcon":
      return <ArrowPathIcon />;
    case "BeakerIcon":
      return <BeakerIcon />;
    case "BellIcon":
      return <BellIcon />;
    case "BoltIcon":
      return <BoltIcon />;
    case "BookOpenIcon":
      return <BookOpenIcon />;
    case "ChartBarIcon":
      return <ChartBarIcon />;
    case "CameraIcon":
      return <CameraIcon />;
    case "ChartPieIcon":
      return <ChartPieIcon />;
    case "CalendarDaysIcon":
      return <CalendarDaysIcon />;
    case "ChatBubbleLeftRightIcon":
      return <ChatBubbleLeftRightIcon />;
    case "ChatBubbleLeftIcon":
      return <ChatBubbleLeftIcon />;
    case "CloudArrowDownIcon":
      return <CloudArrowDownIcon />;
    case "CloudArrowUpIcon":
      return <CloudArrowUpIcon />;
    case "CurrencyEuroIcon":
      return <CurrencyEuroIcon />;
    case "CurrencyPoundIcon":
      return <CurrencyPoundIcon />;
    case "CurrencyDollarIcon":
      return <CurrencyDollarIcon />;
    case "FingerPrintIcon":
      return <FingerPrintIcon />;
    case "HandThumbDownIcon":
      return <HandThumbDownIcon />;
    case "HandThumbUpIcon":
      return <HandThumbUpIcon />;
    case "HeartIcon":
      return <HeartIcon />;
    case "LockClosedIcon":
      return <LockClosedIcon />;
    case "LockOpenIcon":
      return <LockOpenIcon />;
    case "PhoneIcon":
      return <PhoneIcon />;
    case "TvIcon":
      return <TvIcon />;
    case "XMarkIcon":
      return <XMarkIcon />;

    default:
      return <QuestionMarkCircleIcon />;
  }
};
