import React from "react";
import { useParams } from "react-router-dom";

const PlayRedirect = () => {
  const code = useParams().code;
  window.location.replace("https://p.trackflow.studio/" + code);
  return <div>redirecting to {"https://p.trackflow.studio/" + code}</div>;
};

export default PlayRedirect;
