import React from "react";

const LocalStorage = () => {
  window.addEventListener(
    "message",
    function (e) {
      console.log("received localstorage", e);

      if (e.origin === "https://app.trackflow.studio") {
        localStorage.setItem("a-user", JSON.stringify(e.data));
      }
    },
    false
  );
  return <div>localstorage</div>;
};

export default LocalStorage;
